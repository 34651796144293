import React, { useState } from 'react';
import axios from 'axios';
import { Form, Container } from 'react-bootstrap';
import Button from '../Button';

const ForgotPassword = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_ENDPOINT}/api/request-reset-password/`, { email });
      setMessage('A password reset link has been sent to your email.');
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage('There was an issue with your request. Please try again.');
    }
  };

  const styles = {
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
    },
    container: {
      maxWidth: '400px',
      width: '100%',
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
    heading: {
      marginBottom: '20px',
    },
    formGroup: {
      marginBottom: '20px',
    },
    message: {
      marginTop: '20px',
      color: '#28a745',
    },
  };

  return (
    <div style={styles.page}>
      <Container style={styles.container}>
        <h2 style={styles.heading}>Forgot Your Password?</h2>
        <p>Enter your email to receive a reset link.</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group style={styles.formGroup} controlId="formEmail">
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" block>
            Send Reset Link
          </Button>
        </Form>
        {message && <p style={styles.message}>{message}</p>}
      </Container>
    </div>
  );
};

export default ForgotPassword;