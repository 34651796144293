import React from 'react';

function ContactPage() {
    const contactPageStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    };

    const paragraphStyle = {
        maxWidth: '750px',
    };

    const subtitle2Style = {
        fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
    };

    const videoContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <div style={contactPageStyle}>
            <h1 style={subtitle2Style}>See EdXcelerate in Action</h1>
            <p style={paragraphStyle}>
                We believe that dual enrollment can change the face and pace of public education. Watch the demo video below to see how EdXcelerate can benefit your school.
            </p>
            
            <div style={videoContainerStyle}>
                <iframe 
                    src="https://player.vimeo.com/video/1003249187" 
                    width="500" 
                    height="300" 
                    frameBorder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowFullScreen 
                    title="See EdXcelerate in Action"
                ></iframe>
            </div>
        </div>
    );
}

export default ContactPage;