import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../styles/BlogPost.css';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/blogs/${id}/`);
        setPost(response.data);
      } catch (err) {
        setError('Error fetching the blog post');
      }
    };

    fetchPost();
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!post) return <p>Loading...</p>;

  return (
    <div className="blog-container">
    <h1 className="subtitle4">{post.title}</h1>
    <h2 className="subheading4">{post.subtitle}</h2>
    <p className="blog-date">{post.date}</p>
    <p className="blog-date">{post.author}</p>
    <img src={post.image} alt={post.title} className="blog-image" />
    <div dangerouslySetInnerHTML={{ __html: post.body }} className="blog-paragraph" />
</div>

  );
};

export default BlogPost;
