import React, { useState } from 'react';
import logo from '../../assets/images/logo3.png';
import animatedLogo from '../../assets/images/animation.gif'; // Replace with your GIF path
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

function Header2() {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  function scrollToBlog() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('blog').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToFAQ() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('faq').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToContact() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToAbout() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  const headerStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    padding: '10px 20px',
  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: window.innerWidth <= 767 ? 'center' : 'flex-start', // Center on mobile
    flex: window.innerWidth <= 767 ? 1 : 'none', // Take full width on mobile
  };

  const logoStyle = {
    height: '75px',
  };

  const logoTextStyle = {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#000', // Adjust the color as needed
  };

  const navStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '50px',
  };

  const profileIconStyle = {
    color: 'black',
    cursor: 'pointer',
    fontSize: '26px',
    transition: 'color 0.3s',
  };

  const profileIconHoverStyle = {
    color: '#00008A', // Night Sky Blue color on hover
  };

  const mediaQueryStyle = window.innerWidth <= 767 ? {
    display: 'none',
  } : {};

  

  return (
    <header style={headerStyle}>
      <a href="/" style={logoContainerStyle}>
        <img
          style={logoStyle}
          src={isHovered ? animatedLogo : logo}
          alt="Logo"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        <span style={logoTextStyle}>EdXcelerate</span>
      </a>
      <nav style={{ ...navStyle, ...mediaQueryStyle }}>
        <Button variant="tertiary" onClick={scrollToBlog}>Articles</Button>
        <Button variant="tertiary" onClick={scrollToFAQ}>FAQs</Button>
        <Button variant="tertiary" onClick={scrollToContact}>Demo</Button>
        <Button variant="tertiary" onClick={scrollToAbout}>About Us</Button>
        <Button onClick={() => {
          navigate('/log-in');
          window.scrollTo(0, 0);
        }}>Sign In</Button>
      </nav>
    </header>
  );
}

export default Header2;