import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles/Button.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ children, onClick, variant = 'primary', icon, path }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(path); // Updated logic
  
  return (
    <button 
      className={`${styles.default} ${styles[variant]} ${isActive ? styles.active : ''}`} 
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} className={styles.icon} />}
      {children}
    </button>
  );
};

export default Button;
