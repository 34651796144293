import React from 'react';
import styles from './styles/InputOverlay.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const InputOverlay = ({ title, description, onClose, content }) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.card}>
                <div className={styles.closeButton} onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </div>
                <h1>{title}</h1>
                <p>{description}</p>

                {content}
            </div>
        </div>
    );
};

export default InputOverlay;
