import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import Button from '../Button'; 
import Callout from '../Callout';
import { useNavigate } from 'react-router-dom';
import loadingGif from '../../assets/images/animation.gif';

function StudentsList() {
  const [isLoading, setIsLoading] = useState(true); 

  const [students, setStudents] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/students-in-school/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        setStudents(response.data);
        setIsLoading(false); 
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, [API_ENDPOINT]);

  const handleViewAsStudent = async (studentId) => {
    try {
      const adminToken = localStorage.getItem('token'); // Store the admin token
      const response = await axios.post(`${API_ENDPOINT}/api/view-as-student/`, { studentId }, {
        headers: {
          'Authorization': `Token ${adminToken}`
        }
      });
      localStorage.setItem('adminToken', adminToken); // Save the admin token separately
      localStorage.setItem('token', response.data.token); // Set the new student token
      localStorage.setItem('isAdminViewingAsStudent', 'true'); // Set the admin viewing state
      navigate('/plan'); // Navigate to the plan page
      window.location.reload(); // Reload the page to update the user context
    } catch (error) {
      console.error("Error switching to student view:", error);
    }
  };



  const styles = {
    container: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px',
      marginBottom: '20px',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    h3: {
      marginTop: '0',
      marginBottom: '20px',
    },
    tableContainer: {
      width: '100%',
    },
    callout: {
      padding: '10px',
      marginBottom: '20px',
      backgroundColor: '#f8f9fa',
      borderLeft: '5px solid #007bff',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: '20px',
    },
  };

  return (
    <div className="page-container">
      <div style={styles.container}>
        <div style={styles.content}>
          <h1 style={styles.h3}>Students List</h1>
          <Callout text="Below is the list of all students in your school. You can view and edit a students Four Year Plan by clicking the View Student button." />
          {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
                  </div>
              ) : (
          <div style={styles.tableContainer}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.map(student => (
                  <tr key={student.id}>
                    <td>{student.first_name}</td>
                    <td>{student.last_name}</td>
                    <td>{student.email}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleViewAsStudent(student.id)}>View Student</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentsList;
