import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Modal, Form } from 'react-bootstrap';
import Button from '../Button'; // Custom button component
import Callout from '../Callout'; // Custom callout component
import loadingGif from '../../assets/images/animation.gif';
import { useNavigate } from 'react-router-dom';

function ParentStudentsList() {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [parentCode, setParentCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch linked students when the component loads
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/parent-students/`, {
          headers: { Authorization: `Token ${localStorage.getItem('token')}` },
        });
        setStudents(response.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStudents();
  }, [API_ENDPOINT]);

  const handleAddStudent = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/add-student/`,
        { parent_code: parentCode },
        { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
      );
      setStudents([...students, response.data.student]);
      setShowAddStudentModal(false);
      setParentCode('');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(
        (error.response && error.response.data && error.response.data.error) ||
        'An error occurred while adding the student.'
      );
    }
  };

  const handleViewAsStudent = async (studentId) => {
    try {
      const parentToken = localStorage.getItem('token'); // Save the parent token
      const response = await axios.post(
        `${API_ENDPOINT}/api/view-as-student/`,
        { studentId },
        {
          headers: { Authorization: `Token ${parentToken}` },
        }
      );
      localStorage.setItem('parentToken', parentToken); // Save the parent token separately
      localStorage.setItem('token', response.data.token); // Set the new student token
      localStorage.setItem('isParentViewingAsStudent', 'true'); // Set the parent viewing state
      navigate('/plan'); // Navigate to the student’s plan page
      window.location.reload(); // Reload the page to update the user context
    } catch (error) {
      console.error('Error switching to student view:', error);
      alert('An error occurred while trying to view the student.');
    }
  };

  const styles = {
    container: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      padding: '20px',
      marginBottom: '20px',
    },
    tableContainer: { width: '100%' },
    loading: {
      textAlign: 'center',
      marginTop: '20px',
    },
    error: {
      color: 'red',
      marginTop: '10px',
    },
  };

  return (
    <div className="page-container">
      <div style={styles.container}>
        <h1>My Students</h1>
        <Button onClick={() => setShowAddStudentModal(true)}>Add Student</Button>
        <Callout text={`
            Below is the list of students linked to your account. 
            You can view each student's academic plan by clicking the "View Student" button. 
            To add a new student, simply enter their parent code, 
            which the student can find on their profile page.
          `} />

        {isLoading ? (
          <div style={styles.loading}>
            <img src={loadingGif} alt="Loading..." style={{ width: '100px' }} />
          </div>
        ) : (
          <div style={styles.tableContainer}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 ? (
                  students.map((student) => (
                    <tr key={student.id}>
                      <td>{student.first_name}</td>
                      <td>{student.last_name}</td>
                      <td>{student.email}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => handleViewAsStudent(student.id)}
                        >
                          View Student
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>
                      No students linked to your account.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}

        {/* Add Student Modal */}
        <Modal show={showAddStudentModal} onHide={() => setShowAddStudentModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Student</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Parent Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter parent code"
                  value={parentCode}
                  onChange={(e) => setParentCode(e.target.value)}
                />
              </Form.Group>
            </Form>
            {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddStudentModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddStudent}>
              Add Student
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ParentStudentsList;