import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Callout from '../Callout'; // Assuming you've already created this component
import Button from '../Button'; // Assuming you have a reusable Button component
import CourseCard from './CourseCard2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import loadingGif from '../../assets/images/animation.gif';


const CoursesTab = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const [isAddCourseVisible, setIsAddCourseVisible] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [courses, setCourses] = useState([]); // State for storing courses
  const [pathways, setPathways] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [selectedRequirementIds, setSelectedRequirementIds] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState(null);


  const token = localStorage.getItem('token'); 
  const headers = {
    Authorization: `Token ${token}`,
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Replace with your API endpoint



  useEffect(() => {
    fetchCoursesAndPathways();
  }, []);

  useEffect(() => {
      doSearch();
  }, [selectedRequirementIds]);
  

  const fetchCoursesAndPathways = async () => {
    try {
      const coursesResponse = await axios.get(`${API_ENDPOINT}/api/courses/`, { headers });
      const pathwaysResponse = await axios.get(`${API_ENDPOINT}/api/pathways/`, { headers });
      setPathways(pathwaysResponse.data);
      setCourses(coursesResponse.data);
      setIsLoading(false); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleAddCourse = () => {
      setIsAddCourseVisible(!isAddCourseVisible);
  };
  
  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleAddCourse = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const formData = new FormData(event.target);
    let courseData = Object.fromEntries(formData.entries());

    // If prerequisites are selected, they should be formatted as an array of UUIDs
    // Assuming the selectedPrerequisites state holds an array of selected course IDs
    courseData.prerequisites = selectedPrerequisites;

    console.log(courseData);

    try {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json', // Ensure JSON content type
        };
        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

        const response = await axios.post(`${API_ENDPOINT}/api/courses/create/`, JSON.stringify(courseData), { headers });

        // Assuming response.data is the new course object
        setCourses(prevCourses => [...prevCourses, response.data]);

        // After successful submission, close the form or refresh the courses
        setIsAddCourseVisible(false);
    } catch (error) {
        if (error.response) {
            // Extracting error details from the response
            const errorMessage = JSON.stringify(error.response.data, null, 2);
            alert(`Error adding course: ${errorMessage}`);
        }
    }
  };   

    
    
    // Function to handle search
  const doSearch = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (searchTerm) queryParams.append('search', searchTerm);
      if (selectedRequirementIds) queryParams.append('requirement', selectedRequirementIds);
      console.log(selectedRequirementIds);
      const response = await axios.get(`${API_ENDPOINT}/api/courses/?${queryParams.toString()}`, { headers });
      setCourses(response.data); // Update the courses state with the filtered list
      setSearchPerformed(true); // Mark that a search has been performed
       // Mark that a search has been performed
    } catch (error) {
      console.error('Error fetching filtered courses:', error);
      // Handle error, e.g., show an error message
    }
  };
      

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      doSearch(); // Call the search function when Enter key is pressed
    }
  };
  
  const findRequirementLabel = (requirementId) => {
    for (const pathway of pathways) {
      for (const requirement of pathway.requirements) {
        if (requirement.uuid === requirementId) {
          return `${pathway.name}: ${requirement.name}`; // Format as needed
        }
      }
    }
    return null;
  };

  const clearFilter = () => {
    setSelectedRequirementIds(null);
    
  };

  const handleFilter = async (e) => {
    // e.prevsentDefault();
    setIsOverlayVisible(false); // Close the overlay after submission
    doSearch();
  };

  const options = pathways.flatMap(pathway => 
    pathway.requirements.map(requirement => ({
      label: `${pathway.name}: ${requirement.name}`,
      value: requirement.uuid,
    }))
  );
  
  const FilterForm = (
    <form onSubmit={handleFilter}>
      <div className="mb-3">
        <Autocomplete
          options={options}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedRequirementIds(newValue.value);
              setIsOverlayVisible(false); // Assuming you want to close the overlay here
            } else {
              setSelectedRequirementIds(null);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Requirement" variant="outlined" />
          )}
        />
      </div>
    </form>
  );

  const addCourseForm = (
    <form onSubmit={handleAddCourse}>
      {/* Course ID */}
      <div className="mb-3">
        <label htmlFor="course_id" className="form-label">Course ID</label>
        <input type="text" className="form-control" id="course_id" name="course_id" />
      </div>
  
      {/* Title */}
      <div className="mb-3">
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" name="title" />
      </div>
  
      {/* Description */}
      <div className="mb-3">
        <label htmlFor="description" className="form-label">Description</label>
        <textarea className="form-control" id="description" name="description" rows="3"></textarea>
      </div>
  
  
      {/* Units */}
      <div className="mb-3">
        <label htmlFor="units" className="form-label">Units</label>
        <input type="number" className="form-control" id="units" name="units" />
      </div>

      {/* Mode Dropdown */}
      <div className="mb-3">
        <label htmlFor="mode" className="form-label">Mode</label>
        <select className="form-control" id="mode" name="mode">
          <option value="">Select Teaching Mode</option>
          <option value="Online">Online</option>
          <option value="In Person at High School">In Person at High School</option>
          <option value="In Person at College">In Person at College</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="mb-3">
      <label htmlFor="prerequisite" className="form-label">Prerequisites</label>
      <Autocomplete
        multiple
        id="prerequisites"
        options={courses}
        getOptionLabel={(option) => `${option.course_id}: ${option.title}`}
        onChange={(event, newValue) => {
          // newValue is an array of selected course objects
          const selectedUuids = newValue.map((course) => course.uuid); // Extract uuids from selections
          setSelectedPrerequisites(selectedUuids); // Store uuids in state
        }}
        renderInput={(params) => (
          <TextField {...params} label="Prerequisites" variant="outlined" />
        )}
      />
      </div>

  
      {/* Submit Button */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">Add Course</Button>
      </div>
    </form>
  );
  
  // Similar style object for requirement tags in CoursesTab, adapted from CourseCard
  const tagStyle = {
    padding: '0px 10px',
    borderRadius: '5px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000017', 
    margin: '5px',
    cursor: 'pointer',
  };

  const xStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  };

  
  
  return (
    
      <div className="page-container">
      <h1>My Courses</h1>
      <Button onClick={toggleAddCourse}>Add Course</Button>
      <Callout text="Below is a list of all the courses your students will be able to see. This includes courses taught by your school and courses transferable to your school. This will help your students understand what is available to them and how to build their 4 year plan." />
      
      <div style={{ marginBottom: '20px' }}>
      {/* Search bar container */}
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginBottom: '10px' }}>
      <input
        type="text"
        placeholder="Search Courses"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown} 
        style={{
          flexGrow: 1,
          padding: '5px',
          paddingLeft: '30px',
          marginRight: '8px', // Space between input and button
          // Ensure the input height matches the button, adjust as needed
          height: '40px', // Example height, adjust based on your button's height
          boxSizing: 'border-box', // Ensures padding is included in the height calculation
        }}
      />
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#ccc',
          pointerEvents: 'none',
        }}
      />
      {/* Button might need specific height to align perfectly with the input */}
      <Button variant="secondary" onClick={doSearch} style={{ height: '40px', boxSizing: 'border-box' }}>Search</Button>

    </div>


  {/* Filter icon container, now aligned left */}
    <div style={{ display: 'flex', alignSelf: 'flex-start'}}> {/* Align items to the center vertically and left horizontally */}
      <Button variant="secondary" icon={faFilter} onClick={toggleOverlay}>Filter by Requirement</Button>
    </div>
  </div>

  {isOverlayVisible && (
        <InputOverlay
            title="Filter Courses"
            description="Filter by requirement fulfillment."
            onClose={toggleOverlay}
            content={FilterForm}
        />
      )}

    {selectedRequirementIds && (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: '10px 0' }}>
        <div style={tagStyle}>
          {findRequirementLabel(selectedRequirementIds)}
          <span onClick={clearFilter} style={xStyle}>×</span>
        </div>
      </div>
    )}

    {/* Render the search term tag if searchTerm is not empty */}
    {/* {searchTerm && searchPerformed && (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: '10px 0' }}>
        <div style={tagStyle}>
          {`Search: ${searchTerm}`}
          <span onClick={clearSearchTerm} style={xStyle}>×</span>
        </div>
      </div>
    )} */}



      {isAddCourseVisible && (
        <InputOverlay
            title="Add New Course"
            description="Enter the details for the new course below."
            onClose={toggleAddCourse}
            content={addCourseForm}
        />
      )}
      
      {isLoading ? (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
              </div>
          ) : (

      <div style={{ marginTop: '20px' }}>
        {/* Placeholder for course cards */}
        <div>
        {courses.map(course => (
          <CourseCard key={course.UUID} course={course} />
        ))}
      </div>
      </div>
    )}
    </div>
  );
}

export default CoursesTab;
