import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import axios from 'axios';
import '../styles/SignUpPage.css';  // Import the CSS for this component

function AdminOnboarding() {
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const [schoolName, setSchoolName] = useState('');
  const [schoolType, setSchoolType] = useState(''); // New state
  const [state, setState] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleCreateSchool = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Token ${token}` };
      const current_user = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
      const userId = current_user.data.user_id;
      const role = current_user.data.user_type;

      const response = await axios.post(`${API_ENDPOINT}/api/schools/`, {
        name: schoolName,
        school_type: schoolType, // Send the code
        location: state,
      }, {
        headers: { 'Authorization': `Token ${token}` },
      });

      const schoolId = response.data.uuid;
      await axios.post(`${API_ENDPOINT}/api/set-school-of-record/${schoolId}/`, {}, { headers });

      navigate('/courses');
    } catch (error) {
      console.error('Error creating school', error);
      setError('School creation failed! Please try again.');
    }
  };

  return (
    <Container className="signup-container">
      <h2>Welcome to EdXcelerate!</h2>
      <p>Create your school page to start offering transferable courses.</p>
      {success && <Alert variant="success">School created successfully!</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleCreateSchool}>
                <Form.Group className="mb-3" controlId="formSchoolName">
                    <Form.Label className="bold-label">What is the name of your school?</Form.Label>
                    <Form.Control type="text" value={schoolName} onChange={(e) => setSchoolName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formState">
                    <Form.Label className="bold-label">What state is your school in?</Form.Label>
                    <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSchoolType">
                <Form.Label className="bold-label">What type of school is it?</Form.Label>
                <Form.Select value={schoolType} onChange={(e) => setSchoolType(e.target.value)}>
                    <option value="" disabled>Select school type</option>
                    <option value="HS">High School</option>
                    <option value="CLG">College</option>
                </Form.Select>
                </Form.Group>
                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Create School
                    </Button>
                </div>
            </Form>
    </Container>
  );
}

export default AdminOnboarding;
