import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import '../styles/SignUp.css';

function Profile() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [userType, setUserType] = useState("");
    const [school, setSchool] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [parentCode, setParentCode] = useState(null);
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the current user details
        const fetchCurrentUser = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                const userData = response.data;
                setFirstName(userData.first_name);
                setLastName(userData.last_name);
                setEmail(userData.email);
                setSchool(userData.school.name);
                setUserType(userData.user_type === 2 ? 'Administrator' : 'Student');

                if (userData.user_type !== 2) {
                    setParentCode(userData.parent_code); // Assuming parent_code is included in API response for students
                }

                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching current user:", error);
                setIsLoading(false);
            }
        };

        fetchCurrentUser();
    }, [API_ENDPOINT, token]);

    const handleSignOut = () => {
        localStorage.removeItem('token');
        navigate('/');
        window.location.reload();
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='page-container'>
            <Container className="signup-container">
                <h2 className="center-text">My Profile</h2>
                <Form>
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label className="bold-label">First Name</Form.Label>
                        <Form.Control type="text" value={firstName} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label className="bold-label">Last Name</Form.Label>
                        <Form.Control type="text" value={lastName} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSchool">
                        <Form.Label className="bold-label">School</Form.Label>
                        <Form.Control type="text" value={school} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label className="bold-label">Email</Form.Label>
                        <Form.Control type="email" value={email} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUserType">
                        <Form.Label className="bold-label">User Type</Form.Label>
                        <Form.Control type="text" value={userType} readOnly />
                    </Form.Group>
                    {userType === 'Student' && (
                        <Form.Group className="mb-3" controlId="formParentCode">
                            <Form.Label className="bold-label">Parent Code</Form.Label>
                            <Form.Control type="text" value={parentCode || "N/A"} readOnly />
                        </Form.Group>
                    )}
                    <div className="center-text">
                        <Button variant="primary" onClick={handleSignOut} className="btn-block custom-button">
                            Sign Out
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default Profile;