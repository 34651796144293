import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

function CourseCard({ course }) {
  const navigate = useNavigate();
  const { id, course_number, title, subtitle, image, date, progress } = course;

  const cardStyle = {
    cursor: 'pointer',
    width: '18rem',
    minWidth: '18rem',
    height: '24rem',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
    border: 'none', // Remove the border from the card
  };

  const cardBody = {
    border: 'none', // Ensure no border on the Card.Body
  };

  const cardImgStyle = {
    width: '100%',
    height: '150px',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: 'none', // Ensure no border on the image container
  };

  const dateStyle = {
    fontSize: '0.8em',
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    display: 'inline-block',
    border: 'none', // Remove any border from the date label
  };

  const titleStyle = {
    marginTop: '0px',
    fontWeight: 'bold',
    border: 'none', // Ensure no border on the title
  };

  return (
    <div style={cardStyle} onClick={() => navigate(`/blog-page/${id}`)}>
      <Card style={{ border: 'none' }}> {/* Ensure no border on the Card */}
        <div style={cardImgStyle}></div>
        <Card.Body style={cardBody}>
          <Card.Subtitle className="mb-2" style={dateStyle}>{date}</Card.Subtitle>
          <h4 style={titleStyle}>{title}</h4>
          <p>{subtitle}</p>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CourseCard;