import React, { useState } from 'react';
import logo from '../../assets/images/logo3.png';
import animatedLogo from '../../assets/images/animation.gif'; // Replace with your GIF path
import styles from '../styles/Navbar.module.css';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


function Header2() {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <header className={styles.header}>
      <a href="/">
      <img
        className={styles.logo}
        src={isHovered ? animatedLogo : logo}
        alt="Logo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      </a>
      <nav className={styles.nav}>
      <div className={styles.profileIcon} onClick={() => navigate('/profile')}>
          <FontAwesomeIcon icon={faUserCircle} size="lg" />
        </div>
      </nav>
    </header>


  );
}

export default Header2;
