import React from 'react';

function Footer() {
    const style = {
        margin: '50px 0'  /* adjust this value as needed */
    };

    const currentYear = new Date().getFullYear();

    return (
        <footer style={style}>
            © {currentYear} EdXcelerate
        </footer>
    );
}

export default Footer;
