import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

function Header() {
  const navigate = useNavigate();

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 80px',
    backgroundColor: '#fff',
    flexDirection: window.innerWidth <= 767 ? 'column' : 'row', // Stack items vertically on mobile
    padding: window.innerWidth <= 767 ? '20px' : '40px 80px', // Adjust padding on mobile
  };

  const textContainerStyle = {
    flex: '1',
    padding: window.innerWidth <= 767 ? '0 20px' : '0 40px', // Smaller margin on mobile
    textAlign: 'left',
  };

  const titleStyle = {
    fontSize: window.innerWidth <= 767 ? '2.5rem' : '4.25rem', // Smaller text size on mobile
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
  };

  const subtitleStyle = {
    lineHeight: '1.5',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    fontSize: window.innerWidth <= 767 ? '1rem' : '1.25rem', // Adjust font size on mobile
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '15px',
    flexDirection: window.innerWidth <= 767 ? 'column' : 'row', // Stack buttons on mobile
  };

  const lottieContainerStyle = {
    flex: '1',
    display: window.innerWidth <= 767 ? 'none' : 'flex', // Hide Lottie on mobile
    justifyContent: 'center',
    alignItems: 'center',
  };

  const lottiePlayerStyle = {
    width: '100%',
    maxWidth: '500px',
    flexShrink: 0,
  };

  return (
    <header style={headerStyle}>
      <div style={textContainerStyle}>
        <h1 style={titleStyle}>The New Face and Pace of Education</h1>
        <h4 style={subtitleStyle}>
          EdXcelerate empowers students to take control of their education by leveraging dual enrollment and the unique pathways available to them. We provide equitable educational planning, seamless course transferability, and robust support for administrators.
        </h4>
        <div style={buttonContainerStyle}>
          <Button onClick={() => {
            navigate('/sign-up');
            window.scrollTo(0, 0);
          }}>Sign Up</Button>
          <Button variant="secondary" onClick={scrollToContact}>Learn More</Button>
        </div>
      </div>
      <div style={lottieContainerStyle}>
        <div style={lottiePlayerStyle} id="lottie-component">
          <lottie-player 
            src="https://lottie.host/608d50d0-8dc2-4110-8e28-cffa8b184187/VlC9uvxxb4.json" 
            background="transparent" 
            speed="1" 
            style={{ width: '100%', height: 'auto' }} 
            loop 
            autoplay>
          </lottie-player>
        </div>
      </div>
    </header>
  );
}

function scrollToContact() {
  setTimeout(() => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  }, 0);
}




export default Header;