import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeHeader from './Home-Header';
import LottieComponent from './LottieComponent';
import LottieComponent2 from './LottieComponent2';
import LottieComponent3 from './LottieComponent3';
import FAQ from './FAQ';
import Contact from './ContactPage';
import Footer2 from './Footer2';
import Blog from './Blog';
import About from './About'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function HomePage() {
  const [blogs, setBlogs] = useState([]);
  const [loadingBlogs, setLoadingBlogs] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/blogs/`);
        setBlogs(response.data);  // Blogs are already sorted by the backend
      } catch (err) {
        setError('Error fetching the blogs');
      } finally {
        setLoadingBlogs(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div style={{ padding: '10px' }}>
      <HomeHeader />
      <div id="lottie-component">
        <LottieComponent />
      </div>
      <LottieComponent2 />
      {/* <LottieComponent3 /> */}
      <div id="blog">
        {loadingBlogs && <p>Loading blogs...</p>}
        {error && <p>{error}</p>}
        {!loadingBlogs && !error && <Blog title="Recent Articles" courses={blogs} />}
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="about">
        <About />
      </div>
      <Footer2 />
    </div>
    
  );
}

export default HomePage;