import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

function Header() {
  const navigate = useNavigate();

  const headerStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: window.innerWidth <= 767 ? '20px' : '40px 80px',
    backgroundColor: '#fff',
  };

  const titleStyle = {
    fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: window.innerWidth <= 767 ? 'column' : 'row',
    justifyContent: window.innerWidth <= 767 ? 'center' : 'space-between',
    alignItems: window.innerWidth <= 767 ? 'center' : 'flex-start',
    padding: window.innerWidth <= 767 ? '0px 0px' : '0px 50px',
  };

  const lottieContainerStyle = {
    flex: window.innerWidth <= 767 ? '0' : '1',
    display: 'flex',
    justifyContent: window.innerWidth <= 767 ? 'center' : 'center',
    alignItems: 'center',
    marginBottom: window.innerWidth <= 767 ? '20px' : '0',
    marginRight: window.innerWidth > 767 ? '100px' : '0',  // Add margin to create space between Lottie and text
  };

  const lottiePlayerStyle = {
    width: '100%',
    maxWidth: window.innerWidth <= 767 ? '250px' : '550px', // Limit the size on mobile
    flexShrink: 0,
  };

  const textContainerStyle = {
    flex: '1',
    textAlign: 'left',
  };

  const pointContainerStyle = {
    marginTop: window.innerWidth <= 767 ? '20px' : '0',
  };

  const pointStyle = {
    display: 'flex',
    alignItems: 'center', // Center align items vertically
    marginBottom: '20px',
  };

  const iconStyle = {
    minWidth: '40px', // Increase the size of the icons
    height: '40px',   // Increase the size of the icons
    borderRadius: '50%',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px', // Increase the font size for the numbers
    fontWeight: 'bold',
    marginRight: '15px',
  };

  const pointTextContainerStyle = {
    flex: 1,
    maxWidth: '500px',
  };

  const pointHeaderStyle = {
    fontSize: window.innerWidth <= 767 ? '1.15rem' : '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  };

  const pointDescriptionStyle = {
    lineHeight: '1.4',
    fontSize: window.innerWidth <= 767 ? '18px' : '18px',
  };

  return (
    <header style={headerStyle}>
      <h1 style={titleStyle}>The New Pace of Education</h1>
      <div style={contentStyle}>
        <div style={lottieContainerStyle}>
          <div style={lottiePlayerStyle} id="lottie-component">
            <lottie-player 
              src="https://lottie.host/d124a1fb-f8ff-43ef-9846-72b1951ada71/oCKf4dT2x4.json"  
              background="transparent" 
              speed="1" 
              style={{ width: '100%', height: 'auto' }} 
              loop 
              autoplay>
            </lottie-player>
          </div>
        </div>
        <div style={textContainerStyle}>
          <div style={pointContainerStyle}>
            <div style={pointStyle}>
              <div style={iconStyle}>1</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Simplifying Educational Planning</h3>
                <p style={pointDescriptionStyle}>
                  Our platform simplifies four-year planning for students, parents, and administrators. Students can easily identify the courses needed to achieve any academic goal or pathway, whether it’s dual enrollment, high school graduation, CTE, NCAA, and beyond.
                </p>
              </div>
            </div>
            <div style={pointStyle}>
              <div style={iconStyle}>2</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Supporting Individualized Student Goals</h3>
                <p style={pointDescriptionStyle}>
                  As our world and workforce change, our schools should be changing too. Help students understand and achieve their own individualized goals to become college and/or career ready.
                </p>
              </div>
            </div>
            <div style={pointStyle}>
              <div style={iconStyle}>3</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Implementing a K-16 Mindset</h3>
                <p style={pointDescriptionStyle}>
                  With EdXcelerate, it’s easy to blur the arbitrary line between high school and college and implement a K-16 plan and a growth mindset for all students.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;