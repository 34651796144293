import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const PathwayCard = ({ pathway }) => {
  const navigate = useNavigate(); // Hook for navigation
  const fetchedRequirements = pathway.requirements;
  const sortedRequirements = [...fetchedRequirements].sort((a, b) => a.name.localeCompare(b.name));

  const styles = {
    card: {
      width: '100%',
      padding: '20px',
      borderLeft: `8px solid #${pathway.requirements[0].pathway_colors[0]}`,
      borderRadius: '0 10px 10px 0',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      cursor: 'pointer',
    },
    content: {
      marginLeft: '10px',
    },
    h3: {
      marginTop: '0',
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A', // Night Sky Blue
      textDecoration: 'none',
    },
    requirementsContainer: {
      display: 'flex',
      flexDirection: 'column', // Change flex direction to column
      marginTop: '10px',
    },
    requirement: {
      display: 'inline-block', // Add this line
      padding: '0px 10px',
      borderRadius: '5px',
      color: '#000',
      backgroundColor: '#007bff', // Example color, replace with pathway.color in your map function
      marginBottom: '10px', // Optional, for spacing between vertical tags
      fontWeight: '500',
    },
    icon: {
      fontSize: '24px',
      alignSelf: 'flex-start',
    },
  };

  const handleCardClick = () => {
    navigate(`/pathways/${pathway.uuid}`); // Navigate to the pathway page with the given UUID
  };


  return (
    <div style={styles.card} onClick={handleCardClick}>      
      <div style={styles.content}>
        <h3 style={styles.h3}>{pathway.name}</h3>
        <strong style={styles.strong}>{pathway.school_info.name}</strong>        
        <strong >{pathway.school}</strong>
        <p>{pathway.description}</p>
        {/* <strong>Pathway Requirements</strong>
        <div style={styles.requirementsContainer}>
          {sortedRequirements.map((req, index) => (
            <div key={index} style={{ ...styles.requirement, backgroundColor: '#FFE27B' }}>
              {req.name}
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default PathwayCard;
