import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/images/logo3.png';
import animatedLogo from '../../assets/images/animation.gif'; // Replace with your GIF path
import Button from '../Button';
import { useNavigate } from 'react-router-dom';

function ParentStudentNavbar() {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [studentName, setStudentName] = useState('');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchStudentName = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        setStudentName(`${response.data.first_name} ${response.data.last_name}`);
      } catch (error) {
        console.error("Error fetching student name:", error);
      }
    };

    if (localStorage.getItem('isParentViewingAsStudent') === 'true') {
      fetchStudentName();
    }
  }, [API_ENDPOINT]);

  const handleReturnToParentView = async () => {
    try {
      const parentToken = localStorage.getItem('parentToken'); // Retrieve the parent token
      const response = await axios.post(`${API_ENDPOINT}/api/return-to-parent-view/`, { parentToken }, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      });
      localStorage.setItem('token', response.data.token); // Set the new token
      localStorage.removeItem('isParentViewingAsStudent'); // Clear the parent viewing state
      localStorage.removeItem('parentToken'); // Clear the parent token
      navigate('/parent-students'); // Navigate to the parent students list page
      window.location.reload(); // Reload the page to update the user context
    } catch (error) {
      console.error("Error returning to parent view:", error);
    }
  };

  const styles = {
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'white',
      padding: '10px 20px',
    },
    logo: {
      height: '85px',
    },
    navContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'white',
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      gap: '50px',
    },
    profileIcon: {
      color: 'black',
      cursor: 'pointer',
      fontSize: '26px',
      transition: 'color 0.3s',
    },
    profileIconHover: {
      color: '#00008A',
    },
    yellowBar: {
      width: '100%',
      backgroundColor: '#FFE27B', // Yellow color
      color: '#000', // Black text
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 1100, // Ensure it is above the rest of the content
    },
  };

  return (
    <header style={styles.header}>
      {localStorage.getItem('isParentViewingAsStudent') === 'true' && (
        <div style={styles.yellowBar}>
          <b>You are in Student View for: {studentName}</b>
          <Button variant="primary" onClick={handleReturnToParentView}>Return to Parent View</Button>
        </div>
      )}
      <div style={styles.navContent}>
        <a href="/">
          <img
            style={styles.logo}
            src={isHovered ? animatedLogo : logo}
            alt="Logo"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </a>
        <nav style={styles.nav}>
          <Button variant="tertiary" onClick={() => { navigate('/plan'); }}>Academic Plan</Button>
          <Button variant="tertiary" onClick={() => { navigate('/pathways'); }}>Pathways</Button>
          <Button variant="tertiary" onClick={() => { navigate('/courses'); }}>Courses</Button>
        </nav>
      </div>
    </header>
  );
}

export default ParentStudentNavbar;