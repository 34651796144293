import React from 'react';
import '../styles/FAQ.css';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Button } from 'react-bootstrap';

function FAQ() {
  const subtitleStyle = {
    fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
  };

  return (
    <div className="faq-container">
      <h1 style={subtitleStyle}>EdXcelerate FAQs</h1>
      <div className="faq-section">
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is dual enrollment?</Accordion.Header>
        <Accordion.Body>
        Dual enrollment refers to a program that allows high school students to enroll in college courses and earn credit simultaneously for both high school and college.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
      <Accordion.Header>What are the advantages of dual enrollment?</Accordion.Header>
      <Accordion.Body>
        There are many advantages to dual enrollment, summarized by the acronym <strong>DUAL</strong>:
        <br />
        <strong>D</strong>ual credit for both high school and college, allowing students to get a head start on their college education.
        <br />
        <strong>U</strong>ndergraduate degree with less time and money spent, as courses can often be transferred to a 4-year university.
        <br />
        <strong>A</strong>ccessibility to a diverse curriculum, offering students the chance to explore different subjects.
        <br />
        <strong>L</strong>earn about college-level coursework, providing a preview of what to expect in higher education.
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Is EdXcelerate free?</Accordion.Header>
        <Accordion.Body>
        EdXcelerate is built for the public school system. We collaborate with high schools, community colleges, non-profits, and state entities and can be funded through grants or other sources, ensuring it is always free for students.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How does EdXcelerate support high schools in implementing dual enrollment programs?</Accordion.Header>
        <Accordion.Body>
        EdXcelerate provides comprehensive support to high schools, including training for staff and integration with existing school curriculum and pathways.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>How does EdXcelerate handle course transferability?</Accordion.Header>
        <Accordion.Body>
        Our platform features an intuitive transferability engine that helps ensure courses are recognized and accepted by partner institutions, facilitating smooth credit transfers for students.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>How can EdXcelerate enhance a school’s curriculum?</Accordion.Header>
        <Accordion.Body>
        By incorporating dual enrollment courses, EdXcelerate allows schools to offer a broader and more diverse curriculum, giving students opportunities to explore college-level subjects and gain a competitive edge for college admissions.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    </div>
  );
}

export default FAQ;
