import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Callout from '../Callout'; // Assuming you've already created this component
import Button from '../Button'; // Assuming you have a reusable Button component
import CourseCard from './CourseCard2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import loadingGif from '../../assets/images/animation.gif';


const CoursesTab = () => {
  const [isLoading, setIsLoading] = useState(true); 
  const location = useLocation(); // Access location object
  

  const [isAddCourseVisible, setIsAddCourseVisible] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [courses, setCourses] = useState([]); // State for storing courses
  const [pathways, setPathways] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [selectedRequirementIds, setSelectedRequirementIds] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  


  const token = localStorage.getItem('token'); 
  const headers = {
    Authorization: `Token ${token}`,
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Replace with your API endpoint


  useEffect(() => {
    fetchCoursesAndPathways();    
  }, []);

  useEffect(() => {   
      doSearch();
  }, [selectedRequirementIds]);



  

  const fetchCoursesAndPathways = async () => {
    try {
      const coursesResponse = await axios.get(`${API_ENDPOINT}/api/courses/`, { headers });
      const pathwaysResponse = await axios.get(`${API_ENDPOINT}/api/pathways/`, { headers });
      setPathways(pathwaysResponse.data);
      setCourses(coursesResponse.data);
      setIsLoading(false); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  

    
    
    // Function to handle search
  const doSearch = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (searchTerm) queryParams.append('search', searchTerm);
      if (selectedRequirementIds) queryParams.append('requirement', selectedRequirementIds);
      const response = await axios.get(`${API_ENDPOINT}/api/courses/?${queryParams.toString()}`, { headers });
      setCourses(response.data); // Update the courses state with the filtered list
      setSearchPerformed(true); // Mark that a search has been performed
       // Mark that a search has been performed
    } catch (error) {
      console.error('Error fetching filtered courses:', error);
      // Handle error, e.g., show an error message
    }
  };
      

    const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      doSearch(); // Call the search function when Enter key is pressed
    }
  };
  
  const findRequirementLabel = (requirementId) => {
    for (const pathway of pathways) {
      for (const requirement of pathway.requirements) {
        if (requirement.uuid === requirementId) {
          return `${pathway.name}: ${requirement.name}`; // Format as needed
        }
      }
    }
    return null;
  };

  const clearFilter = () => {
    setSelectedRequirementIds(null);
    
  };

  const handleFilter = async (e) => {
    // e.prevsentDefault();
    setIsOverlayVisible(false); // Close the overlay after submission
    doSearch();
  };

  const options = pathways.flatMap(pathway => 
    pathway.requirements.map(requirement => ({
      label: `${pathway.name}: ${requirement.name}`,
      value: requirement.uuid,
    }))
  );
  
  const FilterForm = (
    <form onSubmit={handleFilter}>
      <div className="mb-3">
        <Autocomplete
          options={options}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedRequirementIds(newValue.value);
              setIsOverlayVisible(false); // Assuming you want to close the overlay here
            } else {
              setSelectedRequirementIds(null);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Requirement" variant="outlined" />
          )}
        />
      </div>
    </form>
  );

  
  // Similar style object for requirement tags in CoursesTab, adapted from CourseCard
  const tagStyle = {
    padding: '0px 10px',
    borderRadius: '5px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000017', 
    margin: '5px',
    cursor: 'pointer',
  };

  const xStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  };


  
  return (
    
      <div className="page-container">
      <h1>Explore Courses</h1>
      <Callout text="Below is a list of all courses offered by your school. This includes courses taught by the community college and your high school. You can add courses to your 4 year plan to see how you can fulfill pathways and academic goals." />
      
      <div style={{ marginBottom: '20px' }}>
      {/* Search bar container */}
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginBottom: '10px' }}>
      <input
        type="text"
        placeholder="Search Courses"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown} 
        style={{
          flexGrow: 1,
          padding: '5px',
          paddingLeft: '30px',
          marginRight: '8px', // Space between input and button
          // Ensure the input height matches the button, adjust as needed
          height: '40px', // Example height, adjust based on your button's height
          boxSizing: 'border-box', // Ensures padding is included in the height calculation
        }}
      />
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#ccc',
          pointerEvents: 'none',
        }}
      />
      {/* Button might need specific height to align perfectly with the input */}
      <Button variant="secondary" onClick={doSearch} style={{ height: '40px', boxSizing: 'border-box' }}>Search</Button>

    </div>


  {/* Filter icon container, now aligned left */}
    <div style={{ display: 'flex', alignSelf: 'flex-start'}}> {/* Align items to the center vertically and left horizontally */}
      <Button variant="secondary" icon={faFilter} onClick={toggleOverlay}>Filter by Requirement</Button>
    </div>
  </div>

  {isOverlayVisible && (
        <InputOverlay
            title="Filter Courses"
            description="Filter by requirement fulfillment."
            onClose={toggleOverlay}
            content={FilterForm}
        />
      )}

    {selectedRequirementIds && (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: '10px 0' }}>
        <div style={tagStyle}>
          {findRequirementLabel(selectedRequirementIds)}
          <span onClick={clearFilter} style={xStyle}>×</span>
        </div>
      </div>
    )}

    {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
        </div>
    ) : (
        <div style={{ marginTop: '20px' }}>
        {/* Placeholder for course cards */}
        <div>
        {courses.map(course => (
          <CourseCard key={course.UUID} course={course} />
        ))
        }
        
      </div>
      </div>
       )}
    </div>
  );
}

export default CoursesTab;
