import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

function Header() {
  const navigate = useNavigate();

  const headerStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: window.innerWidth <= 767 ? '20px' : '40px 80px',
    backgroundColor: '#fff',
  };

  const titleStyle = {
    fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: window.innerWidth <= 767 ? 'column' : 'row',
    justifyContent: window.innerWidth <= 767 ? 'center' : 'space-between',
    alignItems: window.innerWidth <= 767 ? 'center' : 'flex-start',
    padding: window.innerWidth <= 767 ? '0px 0px' : '0px 50px',
  };

  const lottieContainerStyle = {
    flex: window.innerWidth <= 767 ? '0' : '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: window.innerWidth <= 767 ? '20px' : '0',
    overflow: 'hidden',
    maxWidth: window.innerWidth <= 767 ? '100%' : '80%', // Adjust width for mobile
    order: window.innerWidth <= 767 ? '1' : '2', // Reorder on mobile to appear below the header but above the points
  };

  const lottiePlayerStyle = {
    width: '125%', // Increase width to ensure full component still fills cropped area
    maxWidth: 'none',
    flexShrink: 0,
    transform: 'translateX(-20%)', // Shift the component left by 20%
    maxWidth: window.innerWidth <= 767 ? '300px' : '650px', // Limit the size on mobile
  };

  const textContainerStyle = {
    flex: '1',
    textAlign: 'left',
    order: window.innerWidth <= 767 ? '2' : '1', // Reorder on mobile to appear below the Lottie component
  };

  const pointContainerStyle = {
    marginTop: window.innerWidth <= 767 ? '20px' : '0',
  };

  const pointStyle = {
    display: 'flex',
    alignItems: 'center', // Center align items vertically
    marginBottom: '20px',
  };

  const iconStyle = {
    minWidth: '40px', // Increase the size of the icons
    height: '40px',   // Increase the size of the icons
    borderRadius: '50%',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px', // Increase the font size for the numbers
    fontWeight: 'bold',
    marginRight: '15px',
  };

  const pointTextContainerStyle = {
    flex: 1,
    maxWidth: '500px',
  };

  const pointHeaderStyle = {
    fontSize: window.innerWidth <= 767 ? '1.15rem' : '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  };

  const pointDescriptionStyle = {
    lineHeight: '1.4',
    fontSize: window.innerWidth <= 767 ? '18px' : '18px',
  };

  return (
    <header style={headerStyle}>
      <h1 style={titleStyle} id="lottie-component">The New Face of Education</h1>
      <div style={contentStyle}>
        <div style={lottieContainerStyle}>
          <div style={lottiePlayerStyle}>
            <lottie-player 
              src="https://lottie.host/a2ed0254-2816-406c-be43-77ef86ee6ae5/NZH0LEDBz5.json" 
              background="transparent" 
              speed="1" 
              style={{ width: '100%', height: 'auto' }} 
              loop 
              autoplay>
            </lottie-player>
          </div>
        </div>
        <div style={textContainerStyle}>
          <div style={pointContainerStyle}>
            <div style={pointStyle}>
              <div style={iconStyle}>1</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Advancing Educational Equity</h3>
                <p style={pointDescriptionStyle}>
                  EdXcelerate’s commitment to bridging the educational gap ensures ALL students make the best of their academic years.
                </p>
              </div>
            </div>
            <div style={pointStyle}>
              <div style={iconStyle}>2</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Enhancing Educational Opportunities</h3>
                <p style={pointDescriptionStyle}>
                  EdXcelerate is revolutionizing the education system by making it easy for high schools to offer transferable dual enrollment courses and other desired pathways to their students.
                </p>
              </div>
            </div>
            <div style={pointStyle}>
              <div style={iconStyle}>3</div>
              <div style={pointTextContainerStyle}>
                <h3 style={pointHeaderStyle}>Leveraging the Value of Counselors</h3>
                <p style={pointDescriptionStyle}>
                  With a 350:1 student-to-counselor ratio, personalized planning is challenging. EdXcelerate helps counselors ensure every student gets the guidance they need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;