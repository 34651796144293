import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import '../styles/SignUp.css';
import '../styles/SignUpPage.css';  // Import the CSS for this component

function StudentOnboarding() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null); // Use null to store the selected school object
  const navigate = useNavigate();

  // Fetch the list of schools when the component mounts
  useEffect(() => {
    axios.get(`${API_ENDPOINT}/api/schools/`, {
      headers: { 'Authorization': 'Token ' + localStorage.getItem('token') },
    })
    .then(response => {
      setSchools(response.data); // No filtering, display all schools
    })
    .catch(error => {
      console.error('Error fetching schools', error);
    });
  }, []);

  async function handleClick(event) {
    event.preventDefault();
    if (!selectedSchool) {
      alert('Please select a school.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Token ${token}` };
    
      // Add the user to the selected school
      await axios.post(`${API_ENDPOINT}/api/schools/${selectedSchool.uuid}/join/`, {}, { headers });
  
      // Set default academic year labels based on the school type
      let academicYearLabels = {};
      if (selectedSchool.school_type === 'HS') {  // High School
        academicYearLabels = {
          '1': '9th Grade',
          '2': '10th Grade',
          '3': '11th Grade',
          '4': '12th Grade',
        };
      } else if (selectedSchool.school_type === 'CLG') {  // College
        academicYearLabels = {
          '1': '1st Year',
          '2': '2nd Year',
        };
      }
  
      // Update the user's academic year labels
      await axios.put(`${API_ENDPOINT}/api/update-user-labels/`, 
        { academic_year_labels: academicYearLabels }, 
        { headers }
      );
  
      // Navigate to courses page after joining
      navigate('/courses');
    } catch (error) {
      console.error('Error joining school', error);
      alert('Failed to join school. Please try again.');
    }
  }

  return (
    <div className="signup-page">
      <Container className="signup-container">
        <h2>Welcome to EdXcelerate!</h2>
        <p>Join your school to start discovering transferable courses.</p>
        <Form onSubmit={handleClick}>
          <Form.Label className="bold-label">What school do you attend?</Form.Label>
          <Autocomplete
            id="schools"
            options={schools} // All the schools fetched from the API
            getOptionLabel={(option) => option.name} // Display the school name
            value={selectedSchool} // Controlled component value
            onChange={(event, newValue) => setSelectedSchool(newValue)} // Update the selected school
            renderInput={(params) => (
              <TextField {...params} label="Select a school" variant="outlined" />
            )}
          />
          <div className="center-text" style={{ marginTop: '20px' }}>
            <Button variant="primary" type="submit" className="btn-block custom-button">
              Join School
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default StudentOnboarding;