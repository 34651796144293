import React from 'react';

function LottieComponent() {
  return (
    <div className="lottie-container2"> {/* justifyContent moved here */}
      <div className="lottie-content2"> {/* justifyContent added here */}      
        <lottie-player 
          src="https://lottie.host/d124a1fb-f8ff-43ef-9846-72b1951ada71/oCKf4dT2x4.json" 
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
        <div className="lottie-text2">
          <h1>Advancing educational equity</h1>
          <p>
          EdXcelerate’s commitment to bridging the educational gap ensures students make the best of their   high school years.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LottieComponent;
