import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faPen, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CourseCard2 from './CourseCard2';
import Button from '../Button'; // Assuming you have a reusable Button component
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const RequirementSection = ({ requirement, onEditRequirement, onEditCourseFulfillment }) => {
  const { completed_courses, required_courses, completed_units, required_units, is_fulfilled } = requirement;
  const [localIsLoading, setLocalIsLoading] = useState(true);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [user_courses, setCourses] = useState([]);
  const [all_courses, setAllCourses] = useState([]);


  // const [requirementProgress, setRequirementProgress] = useState([]);
  

  const navigate = useNavigate();

  const handleFindEligibleCoursesClick = () => {
    navigate('/courses', { state: { selectedRequirementId: requirement.uuid } });
  };
  
  const sortCourses = (allCourses, userCourses) => {
    return allCourses.sort((a, b) => {
      // Compare by `is_scheduled`, putting scheduled courses first
      if (a.is_scheduled && !b.is_scheduled) return -1;
      if (!a.is_scheduled && b.is_scheduled) return 1;
  
      // If both are equally scheduled or not, fallback to another sorting criteria (e.g., title or course_id)
      return a.course_id.localeCompare(b.course_id);
    });
  };

  
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
    };
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
    const fetchCourses = async () => {
      try {
        // Fetch user-specific courses that fulfill this requirement
        const response = await axios.get(`${API_ENDPOINT}/api/courses/by-requirement-and-user/${requirement.uuid}`, { headers });
  
        // Fetch all courses available for this requirement
        // const allCourseResponse = await axios.get(`${API_ENDPOINT}/api/courses/by-requirement/${requirement.uuid}`, { headers });
  
        // Update state with sorted courses
        setCourses(response.data);
        setAllCourses(sortCourses(response.data, response.data));
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
      setLocalIsLoading(false);
    };
  
    if (requirement.uuid) {
      fetchCourses();
    }
  }, [requirement.uuid]);

// useEffect(() => {
//   const token = localStorage.getItem('token'); 
//   const headers = {
//     Authorization: `Token ${token}`,
//   };
//   const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; // Replace with your API endpoint
  
  
  
//   const fetchCourses = async () => {
//     try {
//       const response = await axios.get(`${API_ENDPOINT}/api/courses/by-requirement-and-user/${requirement.uuid}`, { headers });
//       const all_course_response = await axios.get(`${API_ENDPOINT}/api/courses/by-requirement/${requirement.uuid}`, { headers });      
//       setCourses(response.data); // Update state with fetched courses
//       setAllCourses(sortCourses(all_course_response.data, response.data));
//     } catch (error) {
//       console.error('Error fetching courses:', error);
//     }
//     setLocalIsLoading(false);
//   };
  

//   const fetchRequirementProgress = async () => {
//     try {
//       // Fetching requirement progress data
//       const progressResponse = await axios.get(`${API_ENDPOINT}/api/requirement-progress/`, { headers });
//       setRequirementProgress(progressResponse.data); // Store the fetched progress data
//       setIsLoading(false);
//       // Assuming you want to display this data in a specific way, you might need to process it further here
//     } catch (error) {
//       console.error('Error fetching requirement progress:', error);
//     }
//   };


//   if (requirement.uuid) {
//     fetchCourses();
//     // fetchAutocompleteOptions();
//     fetchRequirementProgress();
//   }
//   setLocalIsLoading(false);
// }, [requirement, color]); 


// const findProgress = (requirementId) => {
//   const progress = requirementProgress.find(p => p.uuid === requirementId);
//   return progress || { completedCourses: 0, requiredCourses: 0, completedUnits: 0, requiredUnits: 0 };
// };




  const toggleExpand = async () => {
    setIsExpanded(!isExpanded);
  };

  

  const styles = {
    container: {
      width: '100%', // Ensure the width matches the container
      backgroundColor: '#f5f5f5', // Light grey background
      borderRadius: '8px', // Rounded corners
      padding: '15px', // Padding inside the box
      marginBottom: '20px', // Space between each requirement section
    },
    icon: {
      fontSize: '24px',
      marginRight: '8px',
      cursor: 'pointer',
    },
    editIcon: {
      marginLeft: '8px',
      cursor: 'pointer',
    },
    headerContainer: {
      display: 'flex',  
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    descriptionContainer: {
      marginTop: '10px',
      marginBottom: '10px'
    },
    title: {
      margin: 0,
      lineHeight: '24px', // Adjust as needed
    },
    descriptionText: {
      marginBottom: '5px', // Reduced bottom margin
    },
    boldText: {
      fontWeight: 'bold',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px', // Add space between buttons
      },

  };

  const checkmarkStyle = {
    color: '#4CAF50', // Green color for the checkmark
    marginLeft: '10px',
  };
  
  // const isRequirementFullyMet = (requirementId) => {
  //   const progress = findProgress(requirementId);
  //   return progress.completed_courses >= requirement.number_of_courses && progress.completed_units >= requirement.number_of_units;
  // };

  const isRequirementFullyMet = () => requirement.is_fulfilled;

  // if (localIsLoading) {
  //   return <div>Loading...</div>; // Show a loading message or a spinner
  // }

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer} onClick={toggleExpand}>
        <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} style={styles.icon} />
        <h3 style={styles.title}>
          {requirement.name}
          {isRequirementFullyMet(requirement.uuid) && (
            <FontAwesomeIcon icon={faCheckCircle} style={checkmarkStyle} />
          )}
        </h3>
        
      </div>

      {isExpanded && (
        <div >
        <div style={styles.descriptionContainer}>
          <p style={styles.descriptionText}>{requirement.description}</p>
          <p style={styles.descriptionText}>
            <strong>
              Course Requirement: {completed_courses} fulfilled / {required_courses} required
            </strong>
          </p>
          <p style={styles.descriptionText}>
            <strong>
              Unit Requirement: {completed_units} fulfilled / {required_units} required
            </strong>
          </p>
        </div>
  
          <h3>Eligible Courses</h3>          
          <div>
            {all_courses.length > 0 ? (
              all_courses.map(course => (
                <CourseCard2 key={course.course_id} course={course} />
              ))
            ) : (
              <p>You do not have any courses that fulfill this requirement in your Four Year Plan</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RequirementSection;
