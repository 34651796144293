import React from 'react';
import styles from './styles/Callout.module.css';

const Callout = ({ text }) => (
  <div className={styles.callout}>
    {text}
  </div>
);

export default Callout;
