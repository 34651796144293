import React from 'react';
import BlogCard from './BlogCard';  // Import the CourseCard component
import '../styles/CardSection.css';  // Import the CSS for this component

function CardSection({ title, courses }) {
    const subtitleStyle = {
        fontSize: window.innerWidth <= 767 ? '2.25rem' : '3.5rem',
    fontWeight: 'bold',
    marginBottom: window.innerWidth <= 767 ? '20px' : '40px',
    textAlign: 'center',
      };
    return (
        <div >
        <h1 style={subtitleStyle}>{title}</h1>
        <div className="card-section">            
            <div className="card-list">
                {courses.map(course => <BlogCard key={course.id} course={course} />)}
            </div>
        </div>
        </div>
    );
}

export default CardSection;
