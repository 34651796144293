import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Container } from 'react-bootstrap';
import Button from '../Button';

const ResetPassword = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  

  useEffect(() => {
    console.log(`uidb64: ${uidb64}, token: ${token}`);
  }, [uidb64, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
      return;
    }
    try {
        await axios.post(`${API_ENDPOINT}/api/reset-password/${uidb64}/${token}/`, { password });
      setMessage('Password reset successfully!');
      setTimeout(() => {
        navigate('/log-in'); // Redirect to login page after a short delay
      }, 1500);
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('Failed to reset password. Please try again.');
    }
  };

  const styles = {
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
    },
    container: {
      maxWidth: '400px',
      width: '100%',
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
    heading: {
      marginBottom: '20px',
    },
    formGroup: {
      marginBottom: '20px',
    },
    message: {
      marginTop: '20px',
      color: '#28a745',
    },

    successMessage: {
        marginTop: '20px',
        color: '#28a745',
      },
      errorMessage: {
        marginTop: '20px',
        color: '#dc3545',
      },
  };


  return (
    <div style={styles.page}>
      <Container style={styles.container}>
        <h2 style={styles.heading}>Reset Password</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group style={styles.formGroup} controlId="formPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group style={styles.formGroup} controlId="formConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" block>
            Reset Password
          </Button>
        </Form>
        {message && (
        <p style={message.includes('success') ? styles.successMessage : styles.errorMessage}>
            {message}
        </p>
        )}
      </Container>
    </div>
  );
};

export default ResetPassword;